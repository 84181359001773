import {
    postRequest,
    updateRequest,
    getRequest,
} from "@/utils/http";

/** 查询出库记录集合 */
export const getStockOutOrderList = (parm) => getRequest(`/WarehouseManage/StockOut`, parm);
/**查询单条出库记录 */
export const getStockOutOrder = (id) => getRequest(`/WarehouseManage/StockOut/${id}`);
/** 新建出库记录 */
export const createStockOutOrder = (parm) => postRequest(`/WarehouseManage/StockOut`, parm);
/** 修改销售订单 */
export const updateSalesOrder = (id, parm) => updateRequest(`/common/SalesOrder/update/${id}`, parm);
/** 修改销售订单 */
export const updateStateSalesOrder = (id, parm) => updateRequest(`/common/SalesOrder/updateState/${id}`, parm);

/** 导入创建销售订单 */
export const CreateImportAsync = (parm) => postRequest(`/common/SalesOrder/createImport`, parm);

/** 导入销售订单 */
export const ImportSalesOrderAsync = (parm) => postRequest(`/common/SalesOrder/importSalesOrder`, parm);

/**
 * 出库类型接口
 * @returns
 */
export const getOutTypes = () => getRequest(`/WarehouseManage/StockOut/types`);

/**
 * 出库状态接口
 * @returns
 */
export const getOutStatuses = () => getRequest(`/WarehouseManage/StockOut/status`);

/**
 * 出库仓库接口
 * @returns
 */
export const getOutWarehouses = () => getRequest(`/WarehouseManage/Warehouse/dropdown`);
