<template>
  <div class="inbound">
      <div class="info">
        <h1>出库单列表</h1>
        <div class="filter-term">
          <a-row>
            <a-col :span="7">
              <label>出库单号</label>
              <a-input placeholder="请输入" v-model="param.code"/>
            </a-col>
            <a-col :span="7">
              <label>出库类型</label>
              <a-select v-model="param.stockOutType" allowClear>
                <a-select-option v-for="item in outTypeOptions" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="7">
              <label>操作人</label>
              <a-input placeholder="请输入" />
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="7">
              <label>出库仓库</label>
              <a-select v-model="param.warehouseId" allowClear>
                <a-select-option v-for="item in outWarehouseOptions" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="7">
              <label>状态</label>
              <a-select v-model="param.stockOutStatus" allowClear>
                <a-select-option v-for="item in outStatusOptions" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
              </a-select>
            </a-col>
            <a-col :span="7">
              <a-button type="primary" @click="getList">查询</a-button>
            </a-col>
          </a-row>
          <a-button type="primary" class="add-btn" @click="goAdd">新增出库单</a-button>
        </div>
        <a-table :columns="columns" :data-source="data" :pagination="pagination">
          <span slot="action" class="href-btn">
            <a>执行出库</a>
            <a>查看详情</a>
          </span>
        </a-table>
      </div>
  </div>
</template>

<script>
import { getStockOutOrderList,getOutTypes,getOutStatuses,getOutWarehouses } from "@/api/stockout.js";
const columns = [
  {
    title: '出库单号',
    dataIndex: 'inboundNum',
  },
  {
    title: '来源单号',
    dataIndex: 'sourceNum',
  },
  {
    title: '出库类型',
    dataIndex: 'inboundType',
  },
  {
    title: '出库数量',
    dataIndex: 'inboundDigit',
  },
  {
    title: '操作人',
    dataIndex: 'operater',
  },
  {
    title: '操作时间',
    dataIndex: 'operateTime',
  },
  {
    title: '状态',
    dataIndex: 'state',
  },
  {
    title: '出库仓库',
    dataIndex: 'inboundDepot',
  },
  {
    title: '备注',
    dataIndex: 'remark',
  },
  {
    title: '操作',
    scopedSlots: { customRender: 'action' },
  },
];

const data = [];
export default {
  data() {
    return {
      data,
      columns,
      outTypeOptions:[],
      outStatusOptions:[],
      outWarehouseOptions:[],
      pagination: {
        current: 1,
        total: 0,
        pageSize: 10, //每页中显示10条数据
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "50", "100"], //每页中显示的数据
        showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
      },
      param:{
        code:'',
        stockOutType:null,
        stockOutStatus:null,
        warehouseId:null,
      }
    };
  },
  created(){
    this.getTypes();
    this.getStatuses();
    this.getWarehouses();
    this.getList();
  },
  methods: {
    getList(){
      getStockOutOrderList(this.param).then((res) => {
        this.data=res.items;
      }).catch(()=>{

      })
    },
    getTypes(){
      getOutTypes().then((res)=>{
        this.outTypeOptions=res;
      })
    },
    getStatuses(){
      getOutStatuses().then((res)=>{
        this.outStatusOptions=res;
      })
    },
    getWarehouses(){
      getOutWarehouses().then((res)=>{
        this.outWarehouseOptions=res;
      })
    },
    // 跳转新增入库单
    goAdd() {
      this.$router.push({ path: `/Storage/AddOutbound` });
    },
  },
};
</script>

<style lang="scss" scoped>
  .inbound{
    padding: 30px;

    .info{
      background-color: #ffffff;
      padding: 30px;

      h1{
        font-size: 18px;
        font-weight: bold;
        border-bottom: 1px solid #dddddd;
        padding-bottom: 10px;
      }

      .filter-term{
        margin: 20px 0;
        .ant-row{
          margin-bottom: 15px;

          label{
            margin-right: 10px;
            width: 60px;
            display: inline-block;
            font-size: 14px;
          }

          .ant-input{
            width: 50%;
          }

          .ant-select{
            width: 50%;
          }
        } 
        .add-btn{
          margin-top: 20px;
        }
      }

      .href-btn a{
        margin-right: 10px;
      }
    }
  }
</style>
